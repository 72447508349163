import {ChangeEvent, useState} from "react";
import {TextField} from "@mui/material";
import CopyButton from "./CopyButton";
import utf8 from 'utf8';
import './Base64.css';

function Base64Encoder() {
  const [text, setText] = useState('')
  const [base64, setBase64] = useState('')

  const updateBase64Text = (evt: ChangeEvent<HTMLInputElement>) => {
    const newText = evt.target.value
    setText(newText)
    setBase64(btoa(utf8.encode(newText)))
  }

  return (
          <div className={'Panel'}>
            <TextField
                    className={'Text-input'}
                    name={'text'}
                    label={'Input text'}
                    value={text}
                    onChange={updateBase64Text}
            ></TextField>
            <div className={'FlexRow'}>
              <TextField
                      className={'FlexRow-textField'}
                      name={'base64text'}
                      label={'Base64'}
                      value={base64}
                      disabled={true}
              ></TextField>
              <CopyButton
                      className={'Base64-copyButton'}
                      sourceSelector={'input[name="base64text"]'}
                      aria-label={'copy to clipboard'}
              />
            </div>
          </div>
  )
}

export default Base64Encoder;