import NanoDate from "./NanoDate"

function convertTextToNanoDate(text: string, unit: string): NanoDate {
    assertValidUnit(unit)

    let milli: number = 0
    let nano6: number = 0

    switch (unit) {
        case 'second':
            milli = Number(text) * 1_000
            break
        case 'milli':
            milli = Number(text)
            break
        case 'micro':
            milli = Number(text.slice(0, -3))
            nano6 = Number(text.slice(-3)) * 1_000
            break
        case 'nano':
            milli = Number(text.slice(0, -6))
            nano6 = Number(text.slice(-6))
    }

    return new NanoDate(milli, nano6)
}

function convertNanoDateToText(nanoDate: NanoDate, unit: string): string {
    assertValidUnit(unit)

    switch (unit) {
        case 'second':
            return nanoDate.getUnixSecond().toString()
        case 'milli':
            return nanoDate.getTime().toString()
        case 'micro':
            return nanoDate.getTime().toString() + Math.trunc(nanoDate.getNano6() / 1_000).toString().padStart(3, '0')
        case 'nano':
            return nanoDate.getTime().toString() + nanoDate.getNano6().toString().padStart(6, '0')
    }

    return 'unknown unit: ' + unit
}

function assertValidUnit(unit: string) {
    if (!['second', 'milli', 'micro', 'nano'].includes(unit)) {
        throw new Error(`Unsupported unit: ${unit}`)
    }
}

export { convertTextToNanoDate, convertNanoDateToText }
