class NanoDate extends Date {
    private nano6: number

    public getNano6(): number {
        return this.nano6
    }

    constructor(
        src: number | string | Date | NanoDate = new Date(),
        nano6: number = 0
    ) {
        if (src instanceof NanoDate) {
            super(src.getTime())
            this.nano6 = src.nano6
        } else {
            super(src)
            this.nano6 = nano6
        }
    }

    getUnixSecond():  number {
        return Math.trunc(this.getTime() / 1000)
    }

    getUnixMicro(): number {
        return this.getTime() * 1000 + Math.trunc(this.nano6 / 1000)
    }

    getUnixNano(): number {
        return this.getTime() * 1000000 + this.nano6
    }
}

export default NanoDate
