import { IconButton } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

function CopyButton({className, sourceSelector}: {className: string, sourceSelector: string}) {
    const copySourceText = () => {
        const sourceElement = document.querySelector(sourceSelector)
        if (!sourceElement) {
            return
        }

        const text = sourceElement instanceof HTMLInputElement
                ? sourceElement.value
                : sourceElement.textContent

        if (text) {
            navigator.clipboard.writeText(text)
        }
    }

    return (
        <IconButton className={className} aria-label='copy to clipboard' onClick={copySourceText}>
            <ContentCopy />
        </IconButton>
    )
}

export default CopyButton
