import {ChangeEvent, useState} from "react";
import {TextField} from "@mui/material";
import CopyButton from "./CopyButton";
import utf8 from "utf8";
import './Base64.css';

function Base64Decoder() {
  const [base64, setBase64] = useState('')
  const [isError, setIsError] = useState(false)
  const [helperText, setHelperText] = useState('')
  const [text, setText] = useState('')

  const decodeBase64 = (evt: ChangeEvent<HTMLInputElement>) => {
    const newBase64 = evt.target.value
    setBase64(newBase64)
    try {
      setText(utf8.decode(atob(newBase64)))
      setIsError(false)
      setHelperText('')
    } catch (e) {
      setIsError(true);
      if (e instanceof DOMException) {
          setHelperText('Not a valid base64')
      }
    }
  }

  return (
          <div className={'Panel'}>
            <TextField
                    className={'Base64-input'}
                    name={'base64'}
                    label={'Base64'}
                    value={base64}
                    error={isError}
                    helperText={helperText}
                    onChange={decodeBase64}
            ></TextField>
            <div className={'FlexRow'}>
              <TextField
                      className={'FlexRow-textField'}
                      name={'text'}
                      label={'Original text'}
                      value={text}
                      disabled={true}
              ></TextField>
              <CopyButton
                      className={'FlexRow-copyButton'}
                      sourceSelector={'input[name="text"]'}
                      aria-label={'copy to clipboard'}
              />
            </div>
          </div>
  )
}

export default Base64Decoder;