import { createTheme, ThemeProvider } from "@mui/material/styles";
import {CssBaseline, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {useState} from "react";
import Base64Encoder from "./Base64Encoder";
import Base64Decoder from "./Base64Decoder";
import './App.css'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});

function Base64() {
  const [value, setValue] = useState('1')

  const onTabChanged = (_: React.SyntheticEvent, index: string) => setValue(index)

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <header className={'App-header'}>
        <TabContext value={value}>
          <Tabs value={value} variant={'fullWidth'} role={'navigation'} onChange={onTabChanged}>
            <Tab label={'Encode'} value={'1'}></Tab>
            <Tab label={'Decode'} value={'2'}></Tab>
          </Tabs>
          <TabPanel value='1'>
            <Base64Encoder></Base64Encoder>
          </TabPanel>
          <TabPanel value='2'>
            <Base64Decoder></Base64Decoder>
          </TabPanel>
        </TabContext>
      </header>
    </ThemeProvider>
  )
}

export default Base64
